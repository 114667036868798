<template>
  <div>
    <div class="image__wrap">
      <img :src="imgSrc" :alt="getImageName(imgSrc)" :title="getImageName(imgSrc)" />
    </div>
    <slot name="title"></slot>
  </div>
</template>

<script>
import { getImageName } from '@/utils/utils'

export default {
  methods: {
    getImageName,
  },
  props: {
    imgSrc: {
      type: String,
      default: '',
    },
  },
}
</script>
<style lang="scss" scoped>
.image__wrap {
  position: relative;
  padding-top: 100%;
}
img {
  height: 100%;
  width: 100%;
  display: block;
  position: absolute;
  top: 0;
}
</style>