<template>
  <main class="container categories position-relative">
    <div v-if="!loading">
      <section
        v-if="$route.params.category"
        class="categories_banner my-1 mb-10"
        :style="{
          ...getBanner,
          backgroundPosition: 'center',
        }"
      >
        <div class="c_banner_text">
          <p
            v-html="
              (blogCategory && blogCategory.description) ||
              'Lorem ipsum dolor sit amet, consectetur <b>adipiscingeros donec</b> '
            "
            class="mb-5"
          >
          </p>
          <a
            v-if="blogCategory && blogCategory.banner_button_visible"
            class="banner_btn py-2 px-4"
            :href="blogCategory.banner_button_link"
            :style="{
              color: blogCategory.banner_button_text_color || '#666666',
              backgroundColor: blogCategory.banner_button_color || '#fff',
            }"
          >
            {{ blogCategory.banner_button_text || 'SHOP NOW' }}
          </a>
        </div>
      </section>

      <section class="d-flex">
        <v-container fluid class="pa-0">
          <v-row no-gutters>
            <v-col
              v-if="$vuetify.breakpoint.mdAndUp"
              sm="3"
              class="block_left ma-0"
            >
              <h4>Categories</h4>
              <NestedCategories :categories="blogCategories" />
            </v-col>
            <v-col
              sm="9"
              class="block_right ma-0"
              :class="{ 'mx-auto': $vuetify.breakpoint.smAndDown }"
            >
              <div class="collections pa-0">
                <v-row>
                  <v-col v-for="item in blogs" :key="item.id" cols="4">
                    <CategoryCard
                      :imgSrc="item.photo"
                      @click.native="openBlog(item)"
                      class="cursor-pointer"
                    >
                      <template #title>
                        <div class="collections_text">
                          <span>{{ item.title }}</span>
                        </div>
                      </template>
                    </CategoryCard>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </section>
    </div>
    <div v-else style="height: 50vh">
      <div
        style="
          position: absolute;
          transform: translate(-50%, -50%);
          left: 50%;
          top: 50%;
        "
      >
        <v-progress-circular indeterminate></v-progress-circular>
      </div>
    </div>
  </main>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import CategoryCard from '../../content/store/CategoryCard.vue'
import NestedCategories from '../../content/store/NestedCategories.vue'
const { mapState, mapActions } = createNamespacedHelpers('blogCategories')
const { mapState: State_blogs, mapActions: Actions_blogs } =
  createNamespacedHelpers('blogs')
const { mapState: State_auth } = createNamespacedHelpers('auth')
export default {
  name: 'Categories',
  components: {
    CategoryCard,
    NestedCategories,
  },
  metaInfo() {
    if (this.blogCategory) {
      const { seo_title, seo_description, seo_key, banner_photo } =
        this.blogCategory
      const capitalizeTitle =
        seo_title.substring(0, 1).toUpperCase() + seo_title.substring(1)
      return {
        title: capitalizeTitle,
        meta: [
          { name: 'keywords', content: seo_key },
          { name: 'description', content: seo_description },
          { property: 'og:locale', content: this.localeLang },
          { property: 'og:title', content: seo_title },
          { property: 'og:description', content: seo_description },
          { property: 'og:url', content: window.location.origin + this.$route.fullPath },
          { property: 'og:site_name', content: 'Tumar Art Group' },
          { property: 'og:image', content: banner_photo },
          { property: 'og:image:secure_url', content: banner_photo },
        ],
      }
    }
  },
  data() {
    return {
      blogCategory: null,
      loading: true,
    }
  },
  computed: {
    ...mapState(['blogCategories']),
    ...State_blogs(['blogs']),
    ...State_auth(['localeLang']),
    getBanner() {
      const bannerStyle = {
        backgroundImage: 'url(/img/img_categ/banner/img.png)',
      }
      if (!this.loading) {
        if (this.blogCategory && this.blogCategory.banner_photo) {
          bannerStyle.backgroundImage = `url('${this.blogCategory.banner_photo}')`
          return bannerStyle
        }
        return bannerStyle
      }
      return bannerStyle
    },
  },
  methods: {
    ...mapActions(['fetchBlogCategories', 'fetchBlogCategoryByUrl']),
    ...Actions_blogs(['fetchBlogs']),
    async openBlog(blog) {
      if (this.$route.params.category) {
        this.$router.push({
          name: 'Generated Blog',
          params: { category: this.blogCategory.url, url: blog.url },
        })
      } else {
        this.$router.push({
          name: 'Generated Blog',
          params: { category: 'category', url: blog.url },
        })
      }
    },
  },
  async created() {
    this.loading = true
    this.fetchBlogCategories()
    if (this.$route.params.category) {
      this.blogCategory = await this.fetchBlogCategoryByUrl(
        this.$route.params.category
      )
      this.fetchBlogs({ category_id: this.blogCategory.id })
    } else {
      this.fetchBlogs(null)
    }
    this.loading = false
  },
}
</script>
<style lang="scss">
.categories {
  .v-slider__thumb {
    border-radius: unset;
    width: 3px;
    left: -1.5px;
    &:before {
      content: none;
    }
  }
  .v-slider__thumb-label {
    height: 10px !important;
    width: 10px !important;
    background-color: unset !important;
    color: black;
    border-color: unset !important;
  }
  .v-expansion-panel-content__wrap {
    padding: 8px 8px 8px 16px;
  }
  .bordered {
    .v-badge__badge {
      border: 1px solid;
      border-color: #e1e1e1 !important;
    }
  }
  .sort {
    .v-btn__content {
      font-size: 0.875rem;
      color: #666666;
      font-weight: 500;
      padding: 2px 14px 2px 10px;
      letter-spacing: normal;
    }
  }
}
.sort__menu {
  box-shadow: unset !important;
  border: 1px solid rgba(0, 0, 0, 0.15);
}
</style>
<style lang="scss" scoped>
img {
  width: 100%;
  height: 100%;
  display: block;
}
.p_items {
  & > div {
    border: 1px solid #f2f2f2;
    .p_text {
      border-top: 1px solid #f2f2f2;
    }
  }
}
.v-btn {
  text-transform: unset;
}

.v-expansion-panel--active > .v-expansion-panel-header {
  min-height: unset;
}
.v-expansion-panel-header {
  font-size: 0.875rem;
  color: #a5a5a5;
  font-weight: 500;
  padding-left: 8%;
  min-height: unset;
}
.filter_header {
  .f_items.active a {
    color: #666666;
    font-weight: 700;
  }
  .f_items a {
    font-weight: 500;
  }
}
.v-menu__content {
  background-color: #fff;
}
.size-filter {
  span {
    font-size: 14px;
    color: #666666;
    font-weight: 500;
  }
}
.sort_by {
  font-size: 0.875rem;
  color: #a5a5a5;
  font-weight: 500;
  padding-right: 10px;
}
.c_banner_text {
  width: 50%;
}
.banner_btn {
  white-space: nowrap;
  width: fit-content !important;
  font-size: 12px;
  color: #666666;
  font-weight: bold;
  letter-spacing: 0.1em;
  text-align: center;
  text-transform: uppercase;
}
</style>
